<template>

  <BasePage
    padding
    title="Veicoli"
    subtitle="gestione veicoli regitrati"
    :breadcrumb="$route.matched">

    <template #actions>
      <q-btn
        round
        color="pink"
        icon="mdi-plus"
        @click="show( 'create' )">
        <q-tooltip>Nuovo veicolo</q-tooltip>
      </q-btn>
    </template>

    <!-- detail -->
    <template v-if="detailShow">

      <template v-if="detailEdit">

        <BaseCard
          :header="vehicleFullName">
          <VehicleForm
            :errors="errors"
            v-model="editable">

            <BaseBtn
              label="Salva"
              class="q-mr-md"
              @click="save()"
              :disabled="!detailDirty" />

            <BaseBtn
              label="Annulla"
              color="red"
              @click="show()" />

          </VehicleForm>
        </BaseCard>

        <BaseCard
          v-if="editable.owner.id == null"
          subtitle="Collega al cliente">
          <CustomerAutocomplete
            v-model="editable.owner"
            creable />
        </BaseCard>

        <BaseModal
          confirm
          title="Nuovo cliente"
          label="Salva"
          v-else-if="editable.owner.id < 1"
          @close="editable.owner = {...detail.owner}"
          @confirm="customerCreate"
          :value="true">

          <CustomerForm
            :errors="errors"
            v-model="editable.owner" />

        </BaseModal>

        <CustomerCard
          v-else
          :customer="editable.owner">

          <template #header-action>
            <BaseBtn
              flat
              round
              icon="mdi-pencil"
              class="q-ml-auto"
              @click="customerEdit">
              <q-tooltip>Aggiorna i dati anagrafici</q-tooltip>
            </BaseBtn>
          </template>

          <template #footer>

            <BaseBtn
              label="Scegli un altro cliente"
              color="black"
              class="q-pr-md"
              icon="mdi-account"
              @click="editable.owner.id = null" />

          </template>
        </CustomerCard>

      </template>

      <template v-else>

        <VehicleCard
          title=" "
          :header="vehicleFullName"
          :vehicle="detail">

          <template #footer>

            <BaseBtn
              label="Modifica"
              icon="mdi-pencil"
              class="q-pr-md"
              @click="edit()" />

            <BaseBtn
              label="Elimina"
              icon="mdi-delete-outline"
              class="q-pr-md"
              color="negative"
              @click="destroy()" />

          </template>

          <BaseModal
            confirm
            v-model="detailDestroy"
            @close="show()"
            @confirm="apiDestroy( detail.id )"
            title="Conferma richiesta"
            label="Elimina"
            ko-label="Annulla">
            Rimuovere il veicolo <strong>{{ vehicleFullName }}</strong>?
          </BaseModal>

        </VehicleCard>

        <CustomerCard
          v-if="haveOwner"
          :customer="detail.owner">
          <template #header-action>
            <BaseBtn
              flat
              round
              icon="mdi-pencil"
              class="q-ml-auto"
              @click="customerEdit">
              <q-tooltip>Aggiorna i dati anagrafici</q-tooltip>
            </BaseBtn>
          </template>
        </CustomerCard>

        <BaseBanner
          v-else
          color="negative"
          icon="mdi-account-alert"
          message="Non è stato inserito il cliente">
          <template #action>
            <BaseBtn
              flat
              color="white"
              label="Inserisci"
              @click="edit();" />
          </template>
        </BaseBanner>

      </template>


    </template>

    <!-- table -->
    <VehicleTableList v-else
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="newFilters => filters = newFilters"
      @row-click="(e,row) => show( row.id )"
      @request="onRequest"
    />

  </BasePage>
</template>

<script>
import Customers from '../apis/customers.js'
import CustomerAutocomplete from '../components/customers/CustomerAutocomplete.vue'
import CustomerCard from '../components/customers/CustomerCard.vue'
import CustomerForm from '../components/customers/CustomerForm.vue'
import Vehicles from '../apis/vehicles.js'
import VehicleCard from '../components/vehicles/VehicleCard.vue'
import VehicleTableList from '../components/vehicles/VehicleTableList.vue'
import VehicleForm from '../components/vehicles/VehicleForm.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { computed, toRefs, watch } from '@vue/composition-api'

export default {
  name : 'Vehicles',

  components: {
    CustomerAutocomplete,
    CustomerCard,
    CustomerForm,
    VehicleCard,
    VehicleForm,
    VehicleTableList,
  },

  setup(_,ctx){

    const {
      apiDestroy,
      close,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      save,
      show,
      state,
    } = useApiCrudTable({
      api: Vehicles,
      ctx,
      routes: {
        create: 'vehicleCreate',
        destroy: 'vehicleDestroy',
        edit: 'vehicleEdit',
        index: 'vehicles',
        show: 'vehicleShow',
      },
      filters: {
        sort: 'id',
        dir: 'desc',
        query: '',
      },
    })

    const vehicleFullName = computed( () => {
      let name = 'Nuovo veicolo'

      if( state.detail.model ){

        if( state.detailCreate ) return 'Dati veicolo'

        name = ''
        if( state.detail.model.brand ) name+= `${state.detail.model.brand.name} - `
        name+= `${state.detail.model.name} ${state.detail.model.year}`
      }

      return name
    })

    //breadcrumb name
    watch(() => ctx.root.$route.params.id, () => {
      if( ctx.root.$route.name == 'vehicleShow' ){
        ctx.root.$route.meta.breadcrumb.label = '...'
      }
    })
    watch(() => state.detail.id, () => {
      if( ctx.root.$route.name == 'vehicleShow' ){
        ctx.root.$route.meta.breadcrumb.label = vehicleFullName.value
      }
    })


    const haveOwner = computed( () => state.detail.owner.id != null )

    function customerEdit()
    {
      ctx.root.$router.push({ name: 'customerEdit', params: { id: state.detail.owner.id, action: 'edit' } })
    }

    function customerCreate()
    {
      Customers.store( state.editable.owner )
        .then( response => {
          state.editable.owner = response.data
        })
        .catch( errors.setError )
    }

    return {
      apiDestroy,
      close,
      customerCreate,
      customerEdit,
      destroy,
      haveOwner,
      edit,
      errors,
      loading,
      onRequest,
      save,
      show,
      vehicleFullName,
      ...toRefs(state),
    }
  }
}
</script>

