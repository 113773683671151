<template>

  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns">

    <template #top>

      <VehicleListFilters
        :value="filters"
        @input="filters => $emit( 'filter', filters )" />

    </template>

  </q-table>

</template>

<script>
import VehicleListFilters from './VehicleListFilters.vue'
import { date } from 'quasar'

export default{
  name : 'VehicleTableList',

  components: {
    VehicleListFilters,

  },

  props: {
    filters: {
      type: Object,
    },

  },


  setup(){

    const columns = [
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px'
      },{
        name: 'brand',
        label: 'Marca',
        align: 'left',
        style: 'width:80px',
        field: row => row.model.brand.name,
      },{
        name: 'model',
        label: 'Modello',
        align: 'left',
        field: row => `${row.model.name} ${row.model.year}`,
      },{
        name: 'owner',
        label: 'Cliente',
        align: 'left',
        field: row => row.owner.fullname,
      },{
        name: 'plate_number',
        label: 'Targa veicolo',
        align: 'left',
        field: 'plate_number',
        style: 'width:80px'
      },{
        name: 'serial_number',
        label: 'N° Serie',
        align: 'left',
        field: 'serial_number',
      },{
        name: 'frame_number',
        label: 'N° Telaio',
        align: 'left',
        field: 'frame_number',
      },{
        name: 'mileage',
        label: 'Km',
        align: 'ileft',
        field: 'mileage',
      },{
        name: 'registration_date',
        label: 'Immatricolazione',
        align: 'left',
        field: 'registration_date',
        format: value => date.formatDate( value, 'DD/MM/YYYY' ),
        style: 'width:80px'
      },
    ]

    return {
      columns,
    }
  }

}
</script>
