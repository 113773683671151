<template>

  <q-toolbar class="q-px-none">

    <q-icon
      name="mdi-filter-variant"
      class="q-mr-sm" />

    <BaseInput
      class="q-pb-none q-ml-auto q-mt-sm q-mt-md-none"
      label="Cerca"
      debounce="500"
      outline
      dense
      clearable
      clear-icon="close"
      :value="value.query"
      :stack-label="false"
      @input="query => setFilter({query})">

      <template #append>
        <q-icon name="mdi-magnify" />
      </template>

    </BaseInput>

  </q-toolbar>

</template>

<script>

export default{
  name: 'VehicleListFilters',

  props: {
    value: {
      type: Object,
    },
  },

  setup(props, {emit}){

    function setFilter( value ){
      emit( 'input', { ...props.value, ...value } )
    }

    return {
      setFilter,
    }
  },

}
</script>

